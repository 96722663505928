<template>
  <b-modal id="virusModal" :title="title" size="lg" @show="onShow" @ok="onOk" @hidden="reset">
    <div v-if="virus" class="mb-2">Virus:
      <a :href="'https://www.microsoft.com/en-us/wdsi/threats/malware-encyclopedia-description?Name=' + virus.category"
         target="_blank">{{ virus.category }}</a>
      <div>Pfad: {{ virus.path }}</div>
    </div>
    <b-form @submit.stop.prevent="">
      <b-form-group :invalid-feedback="invalidFeedback.category">
        <label>Kategorie: <span class="text-danger">*</span></label>
        <b-form-select v-model="virusesCategoriesSelect.selected"
                       :options="virusesCategoriesSelect.options" @change="selectCategory"/>
      </b-form-group>
      <div class="mb-2">Beschreibung: {{ description }}</div>
      <b-form-group :invalid-feedback="invalidFeedback.comment">
        <label>Begründung: <span class="text-danger">*</span></label>
        <b-form-textarea :state="state.comment" v-model="form.comment" rows="12"/>
      </b-form-group>
    </b-form>
    <label class="text-required"><span class="text-danger">*</span> Feld wird benötigt</label>
    <template #modal-footer="{ ok, cancel }">
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok()">OK</b-button>
    </template>
  </b-modal>
</template>

<script>
import {getRequest, putRequest} from "@/modules/requests";
import {mapGetters} from "vuex";

export default {
  name: "VirusModal",
  props: ['title', 'type', 'virus'],
  data() {
    return {
      description: null,
      virusesCategoriesSelect: {
        selected: null,
        options: [],
        descriptions: [],
      },
      form: {
        category: "",
        comment: ""
      },
      invalidFeedback: {
        category: '',
        comment: '',
      },
      state: {
        category: null,
        comment: null,
      },
    }
  },
  methods: {
    async onShow() {
      await getRequest('viruses/allow-temp-categories', null, this)
          .then((response) => {
            this.virusesCategoriesSelect.options = []
            this.virusesCategoriesSelect.descriptions = []
            response.data.forEach(element => {
              this.virusesCategoriesSelect.options.push({
                value: element.virus_category_id,
                text: element.displayname,
              })
              this.virusesCategoriesSelect.descriptions.push(element.description)
            })
          })
    },
    async onOk() {
      let data = {
        virus_id: this.virus.id,
        virus_category_id: this.virusesCategoriesSelect.selected,
        comment: this.form.comment,
      }
      if(this.type === 'acknowledge') {
        await putRequest('viruses/acknowledge', data, this, 'Die Virenmeldung wurde erfolgreich zur Kenntnis genommen.')
            .then(() => {
              this.reset()
              this.$emit('refresh')
            })
      }
      if(this.type === 'allowTemp') {
        await putRequest('viruses/allow-temp', data, this, 'Die Virenmeldung wurde erfolgreich der temporären Allowlist hinzugefügt.')
            .then(() => {
              this.reset()
              this.$emit('refresh')
            })
      }
    },
    selectCategory() {
      this.description = this.virusesCategoriesSelect.descriptions[this.virusesCategoriesSelect.selected - 1]
    },
    reset() {
      this.description = null
      this.virusesCategoriesSelect.selected = null
      this.form.comment = ''
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
